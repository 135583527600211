code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.footer {
  font-size: 0.75rem;
  flex-shrink: 0;
  width: 100%;
  height: 30px; /* Set the fixed height of the footer here */
  line-height: 30px; /* Vertically center the text there */
  background-color: #f5f5f5;
}

nav {
  flex-shrink: 0;
}

.main-container {
  flex-grow: 1 !important;
  overflow: auto !important;
  height: 100% !important;
}

.main-container-wrap {
  display: block !important;
  min-height: 100% !important;
}

.footer span {
  padding: 0 15px;
}

.bg {
  background: url("./images/bg-pattern.png"), rgba(0, 181, 188, 1);
  background: url("./images/bg-pattern.png"),
    -moz-linear-gradient(left, rgba(0, 181, 188, 1) 0%, rgba(0, 154, 190, 1)
          100%);
  background: url("./images/bg-pattern.png"),
    -webkit-linear-gradient(left, rgba(0, 181, 188, 1) 0%, rgba(0, 154, 190, 1)
          100%);
  background: url("./images/bg-pattern.png"),
    -o-linear-gradient(left, rgba(0, 181, 188, 1) 0%, rgba(0, 154, 190, 1) 100%);
  background: url("./images/bg-pattern.png"),
    -ms-linear-gradient(left, rgba(0, 181, 188, 1) 0%, rgba(0, 154, 190, 1) 100%);
  background: url("./images/bg-pattern.png"),
    linear-gradient(
      to right,
      rgba(0, 181, 188, 1) 0%,
      rgba(0, 154, 190, 1) 100%
    );
}
