.bg-home {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.33),
      rgba(255, 255, 255, 0.33)
    ),
    url("./../images/Poster.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

#home .home-intro {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}
.bgb {
  display: inline-block;
  background: rgb(34, 34, 34); /* for IE */
  background: rgba(34, 34, 34, 0.55);
  color: #fff;
  margin-bottom: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
