/*!
 * bsStepper v1.3.0 (https://github.com/Johann-S/bs-stepper)
 * Copyright 2018 Johann-S <johann.servoire@gmail.com>
 * Licensed under MIT (https://github.com/Johann-S/bs-stepper/blob/master/LICENSE)
 */
.bs-stepper {
  display: block;
}
.bs-stepper.vertical {
  display: -ms-flexbox;
  display: flex;
}
.bs-stepper .bs-stepper-header {
  white-space: nowrap;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.bs-stepper.vertical .bs-stepper-header {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
}
.bs-stepper .bs-stepper-content {
  overflow: hidden;
  padding: 0 24px 24px 24px;
}
.bs-stepper.vertical .bs-stepper-content {
  padding: 0 0 0 48px;
}
.bs-stepper .step {
  display: -ms-flexbox;
  display: flex;
  height: 72px;
  overflow: hidden;
  -ms-flex-align: center;
  align-items: center;
  outline: 0;
  position: relative;
  box-sizing: content-box;
  -webkit-tap-highlight-color: transparent;
}
.bs-stepper.vertical .step {
  width: 100%;
  padding: 0 12px;
}
.bs-stepper .step.active .bs-stepper-circle {
  background: #f5f5f5;
  color: #000;
  font-weight: 700;
  border-color: #f5f5f5;
  /* background: (0, 181, 188, 1);
  background: -moz-linear-gradient(
    left,
    rgba(0, 181, 188, 1) 0%,
    rgba(0, 154, 190, 1) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(0, 181, 188, 1) 0%,
    rgba(0, 154, 190, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(0, 181, 188, 1) 0%,
    rgba(0, 154, 190, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(0, 181, 188, 1) 0%,
    rgba(0, 154, 190, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(0, 181, 188, 1) 0%,
    rgba(0, 154, 190, 1) 100% 
  );
  */
}
.bs-stepper .step:hover .step-trigger:not(:disabled),
.bs-stepper .step:hover a:not(:disabled),
.bs-stepper .step:hover button:not(:disabled) {
  background-color: rgba(0, 0, 0, 0.06);
  cursor: pointer;
  text-decoration: none;
}
.bs-stepper .content.dstepper-block {
  display: block;
}
.bs-stepper .content.dstepper-none {
  display: none;
}
.bs-stepper.vertical .content.fade.dstepper-none {
  visibility: hidden;
}
.bs-stepper .content:not(.fade) {
  display: none;
}
.bs-stepper .content.active:not(.fade) {
  display: block;
}
.bs-stepper .content.fade {
  visibility: hidden;
  transition-duration: 0.3s;
  transition-property: opacity;
}
.bs-stepper.vertical .content {
  visibility: hidden;
  display: inherit;
}
.bs-stepper .content.fade.active {
  visibility: visible;
  opacity: 1;
}
.bs-stepper.vertical .content.active {
  visibility: visible;
}
.bs-stepper .bs-stepper-circle {
  display: inline-block;
  color: #fff;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin-right: 16px;
  text-align: center;
  line-height: 2.1;
  background-color: rgb(0, 101, 196);
}
.bs-stepper .step .step-trigger,
.bs-stepper .step a,
.bs-stepper .step button {
  text-align: center;
  text-decoration: none;
}
.bs-stepper .step .step-trigger:focus,
.bs-stepper .step a:focus,
.bs-stepper .step button:focus {
  text-decoration: none;
}
.bs-stepper .step .step-trigger .bs-stepper-label,
.bs-stepper .step a .bs-stepper-label,
.bs-stepper .step button .bs-stepper-label {
  font-weight: 600;
  color: #212529;
  display: inline-block;
}
.bs-stepper.linear .step:not(.active) .step-trigger .bs-stepper-label,
.bs-stepper.linear .step:not(.active) a .bs-stepper-label,
.bs-stepper.linear .step:not(.active) button .bs-stepper-label {
  color: rgba(0, 0, 0, 0.38);
}
.bs-stepper .line {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  -ms-flex: auto;
  flex: auto;
  height: 0;
  min-width: 10px;
}
.bs-stepper.vertical .line {
  min-height: 32px;
  width: 0;
  min-width: 0;
  border-top: none;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 768px) {
  .bs-stepper:not(.vertical) {
    display: -ms-flexbox;
    display: flex;
  }
  .bs-stepper:not(.vertical) .bs-stepper-header {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .bs-stepper:not(.vertical) .bs-stepper-content {
    padding: 0 0 0 48px;
  }
  .bs-stepper:not(.vertical) .step {
    width: 100%;
    padding: 0 12px;
  }
  .bs-stepper:not(.vertical) .line {
    min-height: 32px;
    width: 0;
    min-width: 0;
    border-top: none;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    margin-right: auto;
    margin-left: auto;
  }
  .bs-stepper:not(.vertical) .content.active {
    visibility: visible;
  }
  .bs-stepper:not(.vertical) .content:not(.active),
  .bs-stepper:not(.vertical) .content:not(.fade):not(.active) {
    visibility: hidden;
    display: unset;
  }
}
.bs-stepper.bgb .step .step-trigger .bs-stepper-label,
.bs-stepper.bgb .step a .bs-stepper-label,
.bs-stepper.bgb .step button .bs-stepper-label {
  color: #f5f5f5;
}
/*# sourceMappingURL=bs-stepper.min.css.map */
