@font-face {
  font-family: "UGentPannoText";
  src: url(/static/media/UGentPannoText-Normal.d29c7f82.ttf) format("truetype");
}
@font-face {
  font-family: "UGentPannoText";
  src: url(/static/media/UGentPannoText-SemiBold.991fd927.ttf) format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "UGentPannoText";
  src: url(/static/media/UGentPannoText-SemiLight.15623546.ttf) format("truetype");
  font-weight: 300;
}

html {
  height: 100%;
  margin: 0;
}
body {
  margin: 0;
  padding: 0;
  font-family: UGentPannoText, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  width: 100%;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
}

.bg-home {
  background-image: -webkit-linear-gradient(
      rgba(255, 255, 255, 0.33),
      rgba(255, 255, 255, 0.33)
    ),
    url(/static/media/Poster.94d8b759.jpg);
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.33),
      rgba(255, 255, 255, 0.33)
    ),
    url(/static/media/Poster.94d8b759.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

#home .home-intro {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}
.bgb {
  display: inline-block;
  background: rgb(34, 34, 34); /* for IE */
  background: rgba(34, 34, 34, 0.55);
  color: #fff;
  margin-bottom: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.custom-cards .card {
  font-size: 1em;
  overflow: hidden;
  padding: 0;
  border: none;
  border-radius: 0.28571429rem;
  box-shadow: 0 10px 6px -6px #777;
}

.custom-cards .card-block {
  font-size: 1em;
  position: relative;
  margin: 0;
  padding: 1em;
  border: none;
  border-top: 1px solid rgba(34, 36, 38, 0.1);
  box-shadow: none;
}

.custom-cards .card-img-top {
  display: block;
  width: 100%;
  height: auto;
}

.custom-cards .card-title {
  font-size: 1.28571429em;
  font-weight: 700;
  line-height: 1.2857em;
}

.custom-cards .card-text {
  clear: both;
  margin-top: 0.5em;
  color: rgba(0, 0, 0, 0.68);
}

.custom-cards .card > a:hover {
  text-decoration: none;
}


.wizard-container {
  padding-top: 50px;
  z-index: 3;
}

.wizard-card {
  min-height: 410px;
  box-shadow: 0 20px 16px -15px rgba(0, 0, 0, 0.57);
}
.wizard-card .wizard-header {
  padding: 15px 15px 15px 15px;
  position: relative;
  border-radius: 3px 3px 0 0;
  z-index: 3;
}
.wizard-card .wizard-header h3 {
  text-align: center;
}
.wizard-card .wizard-title {
  color: #252422;
  font-weight: 300;
  margin: 0;
}
.wizard-card .category {
  font-size: 14px;
  font-weight: 400;
  color: #9a9a9a;
  margin-bottom: 0px;
  text-align: center;
}

.wizard-card .picture:hover {
  border-color: #51a3c5;
}
.wizard-card .nav-pills > li.active > a:after {
  background-color: #51a3c5;
}
.wizard-card .nav-pills > li.active > a {
  color: #51a3c5;
}
.wizard-card .nav-pills .icon-circle.checked {
  border-color: #51a3c5;
}
.wizard-card .choice.active .card-checkboxes {
  color: #51a3c5;
}
.wizard-card .wizard-navigation .progress-bar {
  background-color: #51a3c5;
}

.wizard-card .wizard-navigation {
  position: relative;
}
.wizard-card .wizard-navigation .progress-with-circle {
  position: relative;
  top: 40px;
  z-index: 50;
  height: 4px;
}
.wizard-card .wizard-navigation .progress-with-circle .progress-bar {
  box-shadow: none;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
}
.wizard-card .info-text {
  text-align: center;
  padding-bottom: 18px;
  padding-top: 12px;
}

.wizard-card .description {
  color: #999999;
  font-size: 14px;
}

.wizard-card .wizard-content {
  padding: 0 15px;
}
.wizard-card .wizard-footer {
  padding: 0 15px 5px;
}

.ui-group-buttons .or {
  position: relative;
  float: left;
  width: 0.3em;
  height: 1.3em;
  z-index: 3;
  font-size: 12px;
}
.ui-group-buttons .or:before {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "or";
  background-color: #5a5a5a;
  margin-top: -0.1em;
  margin-left: -0.9em;
  width: 1.8em;
  height: 1.8em;
  line-height: 1.8;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  border-radius: 500px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
.ui-group-buttons .or:after {
  position: absolute;
  top: 0;
  left: 0;
  content: " ";
  width: 0.3em;
  height: 3.2em;
  background-color: rgba(0, 0, 0, 0);
  border-top: 0.8em solid #5a5a5a;
  border-bottom: 0.8em solid #5a5a5a;
}
.ui-group-buttons .or.or-lg {
  height: 1.5em;
  font-size: 16px;
}
.ui-group-buttons .or.or-lg:after {
  height: 3em;
}
.ui-group-buttons .or.or-sm {
  height: 1em;
}
.ui-group-buttons .or.or-sm:after {
  height: 2.5em;
}
.ui-group-buttons .or.or-xs {
  height: 0.25em;
}
.ui-group-buttons .or.or-xs:after {
  height: 1.84em;
  z-index: -1000;
}
.ui-group-buttons {
  display: inline-block;
  vertical-align: middle;
}
.ui-group-buttons:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.ui-group-buttons .btn {
  float: left;
  border-radius: 0;
}
.ui-group-buttons .btn:first-child {
  margin-left: 0;
  border-top-left-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
  padding-right: 15px;
}
.ui-group-buttons .btn:last-child {
  border-top-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
  padding-left: 15px;
}

.methodDetails {
  background: #fefefe;
}

/*!
 * bsStepper v1.3.0 (https://github.com/Johann-S/bs-stepper)
 * Copyright 2018 Johann-S <johann.servoire@gmail.com>
 * Licensed under MIT (https://github.com/Johann-S/bs-stepper/blob/master/LICENSE)
 */
.bs-stepper {
  display: block;
}
.bs-stepper.vertical {
  display: -webkit-flex;
  display: flex;
}
.bs-stepper .bs-stepper-header {
  white-space: nowrap;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.bs-stepper.vertical .bs-stepper-header {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
.bs-stepper .bs-stepper-content {
  overflow: hidden;
  padding: 0 24px 24px 24px;
}
.bs-stepper.vertical .bs-stepper-content {
  padding: 0 0 0 48px;
}
.bs-stepper .step {
  display: -webkit-flex;
  display: flex;
  height: 72px;
  overflow: hidden;
  -webkit-align-items: center;
          align-items: center;
  outline: 0;
  position: relative;
  box-sizing: content-box;
  -webkit-tap-highlight-color: transparent;
}
.bs-stepper.vertical .step {
  width: 100%;
  padding: 0 12px;
}
.bs-stepper .step.active .bs-stepper-circle {
  background: #f5f5f5;
  color: #000;
  font-weight: 700;
  border-color: #f5f5f5;
  /* background: (0, 181, 188, 1);
  background: -moz-linear-gradient(
    left,
    rgba(0, 181, 188, 1) 0%,
    rgba(0, 154, 190, 1) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(0, 181, 188, 1) 0%,
    rgba(0, 154, 190, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(0, 181, 188, 1) 0%,
    rgba(0, 154, 190, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(0, 181, 188, 1) 0%,
    rgba(0, 154, 190, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(0, 181, 188, 1) 0%,
    rgba(0, 154, 190, 1) 100% 
  );
  */
}
.bs-stepper .step:hover .step-trigger:not(:disabled),
.bs-stepper .step:hover a:not(:disabled),
.bs-stepper .step:hover button:not(:disabled) {
  background-color: rgba(0, 0, 0, 0.06);
  cursor: pointer;
  text-decoration: none;
}
.bs-stepper .content.dstepper-block {
  display: block;
}
.bs-stepper .content.dstepper-none {
  display: none;
}
.bs-stepper.vertical .content.fade.dstepper-none {
  visibility: hidden;
}
.bs-stepper .content:not(.fade) {
  display: none;
}
.bs-stepper .content.active:not(.fade) {
  display: block;
}
.bs-stepper .content.fade {
  visibility: hidden;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transition-property: opacity;
  transition-property: opacity;
}
.bs-stepper.vertical .content {
  visibility: hidden;
  display: inherit;
}
.bs-stepper .content.fade.active {
  visibility: visible;
  opacity: 1;
}
.bs-stepper.vertical .content.active {
  visibility: visible;
}
.bs-stepper .bs-stepper-circle {
  display: inline-block;
  color: #fff;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin-right: 16px;
  text-align: center;
  line-height: 2.1;
  background-color: rgb(0, 101, 196);
}
.bs-stepper .step .step-trigger,
.bs-stepper .step a,
.bs-stepper .step button {
  text-align: center;
  text-decoration: none;
}
.bs-stepper .step .step-trigger:focus,
.bs-stepper .step a:focus,
.bs-stepper .step button:focus {
  text-decoration: none;
}
.bs-stepper .step .step-trigger .bs-stepper-label,
.bs-stepper .step a .bs-stepper-label,
.bs-stepper .step button .bs-stepper-label {
  font-weight: 600;
  color: #212529;
  display: inline-block;
}
.bs-stepper.linear .step:not(.active) .step-trigger .bs-stepper-label,
.bs-stepper.linear .step:not(.active) a .bs-stepper-label,
.bs-stepper.linear .step:not(.active) button .bs-stepper-label {
  color: rgba(0, 0, 0, 0.38);
}
.bs-stepper .line {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  -webkit-flex: auto;
          flex: auto;
  height: 0;
  min-width: 10px;
}
.bs-stepper.vertical .line {
  min-height: 32px;
  width: 0;
  min-width: 0;
  border-top: none;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 768px) {
  .bs-stepper:not(.vertical) {
    display: -webkit-flex;
    display: flex;
  }
  .bs-stepper:not(.vertical) .bs-stepper-header {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }
  .bs-stepper:not(.vertical) .bs-stepper-content {
    padding: 0 0 0 48px;
  }
  .bs-stepper:not(.vertical) .step {
    width: 100%;
    padding: 0 12px;
  }
  .bs-stepper:not(.vertical) .line {
    min-height: 32px;
    width: 0;
    min-width: 0;
    border-top: none;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    margin-right: auto;
    margin-left: auto;
  }
  .bs-stepper:not(.vertical) .content.active {
    visibility: visible;
  }
  .bs-stepper:not(.vertical) .content:not(.active),
  .bs-stepper:not(.vertical) .content:not(.fade):not(.active) {
    visibility: hidden;
    display: unset;
  }
}
.bs-stepper.bgb .step .step-trigger .bs-stepper-label,
.bs-stepper.bgb .step a .bs-stepper-label,
.bs-stepper.bgb .step button .bs-stepper-label {
  color: #f5f5f5;
}
/*# sourceMappingURL=bs-stepper.min.css.map */

.methods-intro {
  padding-top: 2.5rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.footer {
  font-size: 0.75rem;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  width: 100%;
  height: 30px; /* Set the fixed height of the footer here */
  line-height: 30px; /* Vertically center the text there */
  background-color: #f5f5f5;
}

nav {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}

.main-container {
  -webkit-flex-grow: 1 !important;
          flex-grow: 1 !important;
  overflow: auto !important;
  height: 100% !important;
}

.main-container-wrap {
  display: block !important;
  min-height: 100% !important;
}

.footer span {
  padding: 0 15px;
}

.bg {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEMAAABkCAMAAADqvX3PAAAAKlBMVEUAAADX19fX19fBwcHT09PX19fW1tbT09PW1tbV1dXOzs7Ozs7BwcHV1dX5uIg2AAAADnRSTlMAPQAKH0czAAApFAAAAHys1goAAAHwSURBVHja7ZfdcuMwCEb1GUIDcd//dZuk2n5rZzCKOzuzFzo3+XNOBAIHtQosxwi0XUw/jjh2qN1pVzV43FJwJIDLuq5tvaNmzxeEZI5wWP/p9v0gypgGYhGH8fL28yyM7ycOJkFlJW3tZDHhRdBjIO22JR6a1BGMgTAWopvr6BC1jSB1MDWyXYfvkpDlg4iiV83lITiuoXZL+U4/ltcNq2MhogaYBWMYjYU8HHfSRdTrCIOLYImemrfz8dMO6DlVGYyFMWz3tmuGY6GgO1ikXVM51JDXurBO0nyEcyPTnvOXvmyb0hzqfV64jUUYw47Re9CZe6EbeC+M39+TcfK/gTFZuxZgOSag7aMASwGkrQW1Y2lLwXRMx3RMx3T8Pw67FpSOcA5RCccOcTMNL05aRw7Fn2kizA7+1VNH6ENAOBgOxiIK888dHPVqh+Q/yeknzQfHkhx+nqyjT0QFzDUd2Skp53lp7PdFnEkYIhzQv/KRzJUV0QdDSON5+S14CEW0vqSTiAPWWDknCLsY0BZxnNP0L/Z9YQEO8yznXZ2yNwfgTu7rNFgj9aamvS9eZljMTNOeoybSGNwQkvZ+3XrCU07t4HmWSBjDLBzEwdSzhMYcRPRZNYmgdjA1FyahcKQIqpKZs8N0TMd0TMd0/BvHF8n9f8tHo7HcAAAAAElFTkSuQmCC), rgba(0, 181, 188, 1);
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEMAAABkCAMAAADqvX3PAAAAKlBMVEUAAADX19fX19fBwcHT09PX19fW1tbT09PW1tbV1dXOzs7Ozs7BwcHV1dX5uIg2AAAADnRSTlMAPQAKH0czAAApFAAAAHys1goAAAHwSURBVHja7ZfdcuMwCEb1GUIDcd//dZuk2n5rZzCKOzuzFzo3+XNOBAIHtQosxwi0XUw/jjh2qN1pVzV43FJwJIDLuq5tvaNmzxeEZI5wWP/p9v0gypgGYhGH8fL28yyM7ycOJkFlJW3tZDHhRdBjIO22JR6a1BGMgTAWopvr6BC1jSB1MDWyXYfvkpDlg4iiV83lITiuoXZL+U4/ltcNq2MhogaYBWMYjYU8HHfSRdTrCIOLYImemrfz8dMO6DlVGYyFMWz3tmuGY6GgO1ikXVM51JDXurBO0nyEcyPTnvOXvmyb0hzqfV64jUUYw47Re9CZe6EbeC+M39+TcfK/gTFZuxZgOSag7aMASwGkrQW1Y2lLwXRMx3RMx3T8Pw67FpSOcA5RCccOcTMNL05aRw7Fn2kizA7+1VNH6ENAOBgOxiIK888dHPVqh+Q/yeknzQfHkhx+nqyjT0QFzDUd2Skp53lp7PdFnEkYIhzQv/KRzJUV0QdDSON5+S14CEW0vqSTiAPWWDknCLsY0BZxnNP0L/Z9YQEO8yznXZ2yNwfgTu7rNFgj9aamvS9eZljMTNOeoybSGNwQkvZ+3XrCU07t4HmWSBjDLBzEwdSzhMYcRPRZNYmgdjA1FyahcKQIqpKZs8N0TMd0TMd0/BvHF8n9f8tHo7HcAAAAAElFTkSuQmCC),
    -webkit-linear-gradient(left, rgba(0, 181, 188, 1) 0%, rgba(0, 154, 190, 1)
          100%);
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEMAAABkCAMAAADqvX3PAAAAKlBMVEUAAADX19fX19fBwcHT09PX19fW1tbT09PW1tbV1dXOzs7Ozs7BwcHV1dX5uIg2AAAADnRSTlMAPQAKH0czAAApFAAAAHys1goAAAHwSURBVHja7ZfdcuMwCEb1GUIDcd//dZuk2n5rZzCKOzuzFzo3+XNOBAIHtQosxwi0XUw/jjh2qN1pVzV43FJwJIDLuq5tvaNmzxeEZI5wWP/p9v0gypgGYhGH8fL28yyM7ycOJkFlJW3tZDHhRdBjIO22JR6a1BGMgTAWopvr6BC1jSB1MDWyXYfvkpDlg4iiV83lITiuoXZL+U4/ltcNq2MhogaYBWMYjYU8HHfSRdTrCIOLYImemrfz8dMO6DlVGYyFMWz3tmuGY6GgO1ikXVM51JDXurBO0nyEcyPTnvOXvmyb0hzqfV64jUUYw47Re9CZe6EbeC+M39+TcfK/gTFZuxZgOSag7aMASwGkrQW1Y2lLwXRMx3RMx3T8Pw67FpSOcA5RCccOcTMNL05aRw7Fn2kizA7+1VNH6ENAOBgOxiIK888dHPVqh+Q/yeknzQfHkhx+nqyjT0QFzDUd2Skp53lp7PdFnEkYIhzQv/KRzJUV0QdDSON5+S14CEW0vqSTiAPWWDknCLsY0BZxnNP0L/Z9YQEO8yznXZ2yNwfgTu7rNFgj9aamvS9eZljMTNOeoybSGNwQkvZ+3XrCU07t4HmWSBjDLBzEwdSzhMYcRPRZNYmgdjA1FyahcKQIqpKZs8N0TMd0TMd0/BvHF8n9f8tHo7HcAAAAAElFTkSuQmCC),
    -webkit-linear-gradient(
      left,
      rgba(0, 181, 188, 1) 0%,
      rgba(0, 154, 190, 1) 100%
    );
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEMAAABkCAMAAADqvX3PAAAAKlBMVEUAAADX19fX19fBwcHT09PX19fW1tbT09PW1tbV1dXOzs7Ozs7BwcHV1dX5uIg2AAAADnRSTlMAPQAKH0czAAApFAAAAHys1goAAAHwSURBVHja7ZfdcuMwCEb1GUIDcd//dZuk2n5rZzCKOzuzFzo3+XNOBAIHtQosxwi0XUw/jjh2qN1pVzV43FJwJIDLuq5tvaNmzxeEZI5wWP/p9v0gypgGYhGH8fL28yyM7ycOJkFlJW3tZDHhRdBjIO22JR6a1BGMgTAWopvr6BC1jSB1MDWyXYfvkpDlg4iiV83lITiuoXZL+U4/ltcNq2MhogaYBWMYjYU8HHfSRdTrCIOLYImemrfz8dMO6DlVGYyFMWz3tmuGY6GgO1ikXVM51JDXurBO0nyEcyPTnvOXvmyb0hzqfV64jUUYw47Re9CZe6EbeC+M39+TcfK/gTFZuxZgOSag7aMASwGkrQW1Y2lLwXRMx3RMx3T8Pw67FpSOcA5RCccOcTMNL05aRw7Fn2kizA7+1VNH6ENAOBgOxiIK888dHPVqh+Q/yeknzQfHkhx+nqyjT0QFzDUd2Skp53lp7PdFnEkYIhzQv/KRzJUV0QdDSON5+S14CEW0vqSTiAPWWDknCLsY0BZxnNP0L/Z9YQEO8yznXZ2yNwfgTu7rNFgj9aamvS9eZljMTNOeoybSGNwQkvZ+3XrCU07t4HmWSBjDLBzEwdSzhMYcRPRZNYmgdjA1FyahcKQIqpKZs8N0TMd0TMd0/BvHF8n9f8tHo7HcAAAAAElFTkSuQmCC),
    linear-gradient(
      to right,
      rgba(0, 181, 188, 1) 0%,
      rgba(0, 154, 190, 1) 100%
    );
}

