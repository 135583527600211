.wizard-container {
  padding-top: 50px;
  z-index: 3;
}

.wizard-card {
  min-height: 410px;
  box-shadow: 0 20px 16px -15px rgba(0, 0, 0, 0.57);
}
.wizard-card .wizard-header {
  padding: 15px 15px 15px 15px;
  position: relative;
  border-radius: 3px 3px 0 0;
  z-index: 3;
}
.wizard-card .wizard-header h3 {
  text-align: center;
}
.wizard-card .wizard-title {
  color: #252422;
  font-weight: 300;
  margin: 0;
}
.wizard-card .category {
  font-size: 14px;
  font-weight: 400;
  color: #9a9a9a;
  margin-bottom: 0px;
  text-align: center;
}

.wizard-card .picture:hover {
  border-color: #51a3c5;
}
.wizard-card .nav-pills > li.active > a:after {
  background-color: #51a3c5;
}
.wizard-card .nav-pills > li.active > a {
  color: #51a3c5;
}
.wizard-card .nav-pills .icon-circle.checked {
  border-color: #51a3c5;
}
.wizard-card .choice.active .card-checkboxes {
  color: #51a3c5;
}
.wizard-card .wizard-navigation .progress-bar {
  background-color: #51a3c5;
}

.wizard-card .wizard-navigation {
  position: relative;
}
.wizard-card .wizard-navigation .progress-with-circle {
  position: relative;
  top: 40px;
  z-index: 50;
  height: 4px;
}
.wizard-card .wizard-navigation .progress-with-circle .progress-bar {
  box-shadow: none;
  -webkit-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease;
}
.wizard-card .info-text {
  text-align: center;
  padding-bottom: 18px;
  padding-top: 12px;
}

.wizard-card .description {
  color: #999999;
  font-size: 14px;
}

.wizard-card .wizard-content {
  padding: 0 15px;
}
.wizard-card .wizard-footer {
  padding: 0 15px 5px;
}

.ui-group-buttons .or {
  position: relative;
  float: left;
  width: 0.3em;
  height: 1.3em;
  z-index: 3;
  font-size: 12px;
}
.ui-group-buttons .or:before {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "or";
  background-color: #5a5a5a;
  margin-top: -0.1em;
  margin-left: -0.9em;
  width: 1.8em;
  height: 1.8em;
  line-height: 1.8;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  border-radius: 500px;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
.ui-group-buttons .or:after {
  position: absolute;
  top: 0;
  left: 0;
  content: " ";
  width: 0.3em;
  height: 3.2em;
  background-color: rgba(0, 0, 0, 0);
  border-top: 0.8em solid #5a5a5a;
  border-bottom: 0.8em solid #5a5a5a;
}
.ui-group-buttons .or.or-lg {
  height: 1.5em;
  font-size: 16px;
}
.ui-group-buttons .or.or-lg:after {
  height: 3em;
}
.ui-group-buttons .or.or-sm {
  height: 1em;
}
.ui-group-buttons .or.or-sm:after {
  height: 2.5em;
}
.ui-group-buttons .or.or-xs {
  height: 0.25em;
}
.ui-group-buttons .or.or-xs:after {
  height: 1.84em;
  z-index: -1000;
}
.ui-group-buttons {
  display: inline-block;
  vertical-align: middle;
}
.ui-group-buttons:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.ui-group-buttons .btn {
  float: left;
  border-radius: 0;
}
.ui-group-buttons .btn:first-child {
  margin-left: 0;
  border-top-left-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
  padding-right: 15px;
}
.ui-group-buttons .btn:last-child {
  border-top-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
  padding-left: 15px;
}
