@font-face {
  font-family: "UGentPannoText";
  src: url("./fonts/UGentPannoText-Normal.ttf") format("truetype");
}
@font-face {
  font-family: "UGentPannoText";
  src: url("./fonts/UGentPannoText-SemiBold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "UGentPannoText";
  src: url("./fonts/UGentPannoText-SemiLight.ttf") format("truetype");
  font-weight: 300;
}

html {
  height: 100%;
  margin: 0;
}
body {
  margin: 0;
  padding: 0;
  font-family: UGentPannoText, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
